<template>
    <main class="inside-wrap">
        <!-- banner -->
        <div class="banner-wrap page-client">
            <div class="banner-title">
                <figure>
                    <h2>客戶專區</h2>
                    <h3>MEMBER</h3>
                </figure>
            </div>
        </div>
        <!-- main -->
        <div class="inside-main no-padding">
            <div class="client-wrap">
                <div class="inside-center">
                    <div class="client-main">
                        <div class="login-form">
                            <h2>客戶登入</h2>
                            <!-- <form action=""> -->
                                <div class="login-input">
                                    <input v-model="account" type="text" id="iAccount" placeholder="請輸入您的帳號" autocomplete="off">
                                    <label for="iAccount">帳號</label>
                                </div>
                                <div class="login-input">
                                    <input v-if="!password_show" v-model="password" type="password" id="iPassword" placeholder="請輸入您的密碼" autocomplete="off">
                                    <input v-if="password_show" v-model="password" type="text" id="iPassword" placeholder="請輸入您的密碼" autocomplete="off">
                                    <label for="iPassword">密碼</label>
                                    <button :class="[{eye_open:password_show}]" class="eye-btn" @click="showPassword"></button>
                                </div>
                                <!-- <div class="login-link">
                                    <a href="">忘記密碼?</a>
                                </div> -->
                                <div class="login-btn">
                                    <!-- <router-link to="/client">登入</router-link>DEMO展示頁面連結 可刪除換下方的 button -->
                                    <button @click="login">登入</button>
                                </div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="inside-bottom"></div>
        </div>
    </main>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: "LoginView",
    data() {
        return {
            password_show: false,
            account: '',
            password: '',
        };
    },
    computed:{
        ...mapState({
            token: state => state.user.token
        })
    },
    created(){
        if(this.token != null ) this.$router.replace('/client')
    },
    mounted() {
    },
    methods:{
        login(){
            const data = {}
            data.account = this.account
            data.password = this.password
            this.$store.dispatch('user/login' , data).then(respones => {
                this.$router.replace('/client')
            }).catch(error => {                
                this.$swal({
                    icon: "error",
                    title: "登入錯誤",
                    text: "請確認帳密是否填寫正確!",
                    confirmButtonText: "確定",
                });
            })
        },
        showPassword(){
            this.password_show = !this.password_show
        }
    }

}
</script>

<style lang="scss" scoped>
.eye_open:before{
    content: "\f06e" !important
}
 
</style>
