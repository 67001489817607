import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import BuyView from "../views/BuyView.vue";
import AboutView from "../views/AboutView.vue";
import NewsView from "../views/NewsView.vue";
import LoginView from "../views/LoginView.vue";
import ClientView from "../views/ClientView.vue";
import ContactView from "../views/ContactView.vue";
import ReportView from "../views/ReportView.vue";
import PartnerView from "../views/PartnerView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
    },
    {
        path: "/buy",
        name: "buy",
        component: BuyView,
    },
    {
        path: "/news",
        name: "news",
        component: NewsView,
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
    },
    {
        path: "/client",
        name: "client",
        component: ClientView,
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactView,
    },
    {
        path: "/report",
        name: "report",
        component: ReportView,
    },
    {
        path: "/partner",
        name: "partner",
        component: PartnerView,
    },
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes,
    linkActiveClass: "is-active",
    linkExactActiveClass: "is-active",
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

export default router;
