<template>
  <main class="inside-wrap">
    <!-- banner -->
    <div class="banner-wrap page-news">
      <div class="banner-title">
        <figure>
          <h2>新聞媒體</h2>
          <h3>NEWS</h3>
        </figure>
      </div>
    </div>
    <!-- main -->
    <div class="inside-main">
      <div class="news-wrap">
        <div class="news-top">
          <div class="inside-center">
            <ul>
              <li v-for="(item, index) in news.slice(0, 4)" :key="index">
                <a :href="item.link" target="_blank">
                  <figure>
                    <span
                      :style="{
                        backgroundImage: `url('${imgUri}${item.image}')`,
                      }"
                    ></span>
                  </figure>
                  <time v-text="$filters.dateFormat(item.add_date, 1)"></time>
                  <h2>
                    <span v-text="item.title"></span>
                  </h2>
                  <p v-text="item.content"></p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="news-list">
          <div class="inside-center">
            <ul>
              <li
                v-for="(item, index) in news"
                :key="index"
                class="fade"
                :class="[{ fadeIn: showIndex >= index }]"
              >
                <a :href="item.link" target="_blank">
                  <figure>
                    <time v-text="$filters.dateFormat(item.add_date, 1)"></time>
                    <h2 v-text="item.title"></h2>
                  </figure>
                </a>
              </li>
            </ul>
          </div>
          <div v-if="loading" class="news-loading"><b></b><b></b><b></b></div>
        </div>
      </div>
      <div class="inside-bottom"></div>
    </div>
  </main>
</template>

<script>
import { getArticle } from "@/api/article";

export default {
  data() {
    return {
      news: [],
      loading: false,
      pageNum: 1,
      showIndex: -1,
    };
  },
  created() {
    // 取得新聞
    this.getNews();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  methods: {
    // 取得新聞
    getNews() {
      var paras = {};
      paras.kind = 1;
      paras.pageSize = 10;
      paras.pageNum = this.pageNum;
      getArticle(paras).then((response) => {
        if (response.code === 200) {
          this.news = [...this.news, ...response.data];
          this.showImg()
        }
        if (response.pages.page_total > this.pageNum) {
          this.pageNum++;
        } else {
          window.removeEventListener("scroll", this.onScroll, true);
        }
        this.loading = false;
      });
    },
    onScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight - 200 && !this.loading) {
        this.loading = true;
        this.getNews();
      }
    },
    showImg() {
      const _this = this;
      this.showIndex++
      if (this.showIndex >= this.news.length) return;
      setTimeout(() => {
        _this.showImg();
      }, 80);
    },
  },
};
</script>

<style scoped>
.fade {
  opacity: 0;
  margin-top: -10px;
  transition: all .5s;
}
.fadeIn {
  opacity: 1;
  margin-top: 0px;
}
</style>