import request from '@/utils/request'

export function getContactType(paras) {
  // let dataQuery = new URLSearchParams(paras).toString()
  
  return request({
    url: '/contactType', //+ dataQuery,
    method: 'get'
  })
}

export function postContact(data) {
  return request({
    url: '/contact',
    method: 'post',
    data
  })
}
