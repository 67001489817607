<template>
    <main class="inside-wrap">
        <!-- banner -->
        <div class="banner-wrap page-buy">
            <div class="banner-title">
                <figure>
                    <h2>我要買綠電</h2>
                    <h3>HOW TO BUY</h3>
                </figure>
            </div>
        </div>
        <!-- main -->
        <div class="inside-main">
            <div class="buy-wrap">
                <div class="inside-center">
                    <div class="buy-top">
                        <div class="buy-text">
                            <h2>驅動企業採購綠電主要因素</h2>
                            <ul>
                                <li v-for="(item,key) in buyGreen" :key="key" v-html="item.context"></li>
                            </ul>
                        </div>
                        <div class="buy-bg"></div>
                    </div>
                    <div class="buy-step">
                        <h2>購電步驟</h2>
                        <ul>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>設定綠電使用目標</span>
                                </h2>
                            </li>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>了解再生能源需求量</span>
                                </h2>
                            </li>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>向售電商洽談</span>
                                    <span>聯絡<b>大自然</b></span>
                                </h2>
                            </li>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>研擬購售電契約</span>
                                    <span>PPA</span>
                                </h2>
                            </li>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>台電協助轉供綠電</span>
                                </h2>
                            </li>
                            <li>
                                <figure></figure>
                                <h2>
                                    <span>取得憑證</span>
                                </h2>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="inside-bottom"></div>
        </div>
    </main>
</template>

<script>
import { getWebconfig } from "@/api/webconfig";

export default {
  name: "BuyGreenView",
  data() {
    return {
      buyGreen: []
    };
  },
  computed: {},
  methods: {
    // 取得
    getBuyData() {
      var paras = {};
      paras.kind = 3;
      getWebconfig(paras).then((response) => {
        if (response.code === 200) {
          this.buyGreen = response.data;
          this.showImg(-1)
        }
      });
    },
    // showImg(n){
    //     const _this = this
    //     if (n > this.partner.length) return
    //     if (this.partner[n] != null) this.partner[n].show = true
    //     setTimeout(()=>{
    //         _this.showImg(n+1)
    //     },80)
    // }
  },
  created() {
    // 取得
    this.getBuyData();
  },
  mounted() {},
};
</script>
