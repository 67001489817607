<template>
    <main class="inside-wrap">
        <!-- banner -->
        <div class="banner-wrap page-about">
            <div class="banner-title">
                <figure>
                    <h2>關於我們</h2>
                    <h3>ABOUT US</h3>
                </figure>
            </div>
        </div>
        <!-- main -->
        <div class="inside-main">
            <div class="about-wrap">
                <div class="inside-center">
                    <div class="about-content">
                        <div class="about-img">
                            <figure>
                                <img src="image/about_img.png" alt="">
                            </figure>
                        </div>
                        <div class="about-text">
                            <figure>
                                <h2><b>大自然能源</b>為能源局核准許可之再生能源售電業</h2>
                                <h3 v-html="aboutData.title"></h3>
                                <p v-html="aboutData.content"></p>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inside-bottom"></div>
        </div>
    </main>
</template>

<script>
import { getWebconfig } from '@/api/webconfig'

export default {
    data() {
        return {
            aboutData: {
                title:"成立於2021年8月25日，公司使命為提供企業之100%綠電投資、開發、供應及顧問之專業服務。",
                content:"公司主要服務客戶象為企業用戶，以達成自主企業減碳目標為主要客群(如：RE100、供應鏈等)。營運至今已協助客戶提供超額超過5,000萬度之綠電來源規畫，對於現階段客戶急迫於降低排碳之情況下，大自然可協助企業找到合理、足夠數量並長期規劃之方案，配合客戶一同成長及能源轉型。"
            }
        }
    },
    computed: {},
    methods: {
        // 取得關於我們
        getAbout() {
            var paras = {}
            paras.kind = 2
            getWebconfig(paras).then((response) => {
                if(response.code === 200){
                    const data = JSON.parse(response.data[0].context)
                    if(data.title != null && data.content != null )
                        this.aboutData = data
                }                
            })
        },
    },
    updated() {
        // scroll animation
        AOS.init({
            once: true,
            easing: "ease-in-out-sine"
        });
    },
    created(){
        this.getAbout()
    },
    mounted() {
    }
}
</script>

<style lang="scss">
</style>

