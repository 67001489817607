<template>
  <main class="inside-wrap">
    <!-- banner -->
    <div class="banner-wrap page-partner">
      <div class="banner-title">
        <figure>
          <h2>合作夥伴</h2>
          <h3>PARTNER</h3>
        </figure>
      </div>
    </div>
    <!-- main -->
    <div class="inside-main">
      <div class="partner-wrap">
        <div class="partner-list">
          <div class="inside-center">
            <ul>
              <li
                v-for="(item, key) in partner"
                :key="key"
                class="fade"
                :class="[{ fadeIn: item.show }]"
              >
                <a :href="item.context" target="_blank">
                  <img
                    :src="`${imgUri}${item.url}`"
                    :alt="item.context"
                    loading="lazy"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="partner-contact">
          <div class="inside-center">
            <figure>
              <h2>想要加入合作夥伴？歡迎與我們聯繫。</h2>
              <router-link to="/contact">聯繫我們</router-link>
            </figure>
          </div>
        </div>
      </div>
      <div class="inside-bottom"></div>
    </div>
  </main>
</template>
<script>
import { getWebconfig } from "@/api/webconfig";

export default {
  name: "PartnerView",
  data() {
    return {
      partner: []
    };
  },
  computed: {},
  methods: {
    // 取得合作夥伴
    getPartner() {
      var paras = {};
      paras.kind = 5;
      getWebconfig(paras).then((response) => {
        if (response.code === 200) {
          this.partner = response.data;
          this.showImg(-1)
        }
      });
    },
    showImg(n){
        const _this = this
        if (n > this.partner.length) return
        if (this.partner[n] != null) this.partner[n].show = true
        setTimeout(()=>{
            _this.showImg(n+1)
        },80)
    }
  },
  created() {
    // 取得合作夥伴
    this.getPartner();
  },
  mounted() {},
};
</script>

<style scoped>
.fade {
  opacity: 0;
  transition: all 1s;
}
.fadeIn {
  opacity: 1;
}
</style>