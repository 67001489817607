<template>
    <article><!-- 登入狀態加入 .is-login 調整導覽列選單位置用 -->
        <header class="header-wrap">
            <div class="header-top">
                <div class="header-center">
                    <figure class="logo-wrap"><router-link to="/"></router-link></figure>
                    <nav class="nav-wrap">
                        <div class="nav-list">
                            <ul>
                                <li class="nav-item">
                                    <router-link to="/about">關於我們</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/buy">我要買綠電</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/news">新聞媒體</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/login">客戶專區</router-link>
                                </li>
                                <!-- <li class="nav-item">
                                    <router-link to="/client">客戶專區</router-link>
                                </li> -->
                                <li class="nav-item">
                                    <router-link to="/contact">聯絡我們</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/report">公開資訊</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/partner">合作夥伴</router-link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <button class="nav-btn"><span><b></b><b></b><b></b><b></b></span></button>
                </div>
            </div>
            <!--登入後顯示-->
            <div v-if="token" class="header-bottom">
                <div class="header-center">
                    <div class="header-client-info">
                        <span><b>{{ userInfo.name }} 您好：</b><router-link to="/client">檢視歷年綠電紀錄</router-link></span>
                        <button @click="logout">登出</button>
                    </div>
                </div>
            </div>
        </header>
        <router-view />
        <footer class="footer-wrap">
            <div class="footer-info">
                <ul>
                    <li>
                        <span>{{ContactInfo.phone}}</span>
                    </li>
                    <li>
                        <span>{{ContactInfo.address}}</span>
                    </li>
                    <li>
                        <span>{{ContactInfo.email}}</span>
                    </li>
                </ul>
            </div>
            <div class="footer-logo"></div>
            <div class="footer-copyright">
                <p>Copyright © 2022  All Right Reserved.</p>
            </div>
        </footer>
        <button class="btn-top"><span></span></button>
    </article>
</template>

<script>
import { mapState } from 'vuex'
import { getWebconfig } from '@/api/webconfig'

export default {
    data() {
        return {
            ContactInfo: {
                address:"臺北市內湖區新湖二路257號3樓",
                phone:"02-87918368",
                email:"service@naturalpower.com"
            }
        }
    },
    computed: {
    ...mapState({
        token: state => state.user.token,
        userInfo: state => state.user.user_info,
    })
    },
    methods: {
        // 取得聯繫資訊
        getContactInfo() {
            var paras = {}
            paras.kind = 1
            getWebconfig(paras).then((response) => {
                if(response.code === 200){
                    const info = JSON.parse(response.data[0].context)
                    if(info.address != null && info.phone != null && info.email != null)
                        this.ContactInfo = info
                }                
            })
        },
        logout(){
            this.$store.dispatch('user/logout').then(()=>{
                this.$router.replace('/')
            })
        }
    },
    updated() {
        // scroll animation
        AOS.init({
            once: true,
            easing: "ease-in-out-sine"
        });
    },
    created(){
        this.$store.dispatch('user/logout')
        this.getContactInfo()
    },
    mounted() {
        // nav
        $(function() {
            $(".nav-btn").on('click', function() {
                $("html").toggleClass("no-scroll");
                $(".nav-wrap").toggleClass("is-active");
                $(this).toggleClass("is-active");
            });
            $(".nav-item").on('click', function() {
                $("html").removeClass("no-scroll");
                $(".nav-wrap, .nav-btn").removeClass("is-active");
                $(".nav-item").find("a").removeClass("is-active");
                $(this).find("a").addClass("is-active");
            });
        });

        // gotop
        $(function() {
            $(window).scroll(function() {
                if ($(this).scrollTop() > 240) {
                    $(".btn-top").fadeIn();
                } else {
                    $(".btn-top").fadeOut();
                }
            });
            $(".btn-top").click(function() {
                $("body, html").animate({
                    scrollTop: 0
                }, 500);
            });
        });

        // scroll animation
        AOS.init({
            once: true,
            easing: "ease-in-out-sine"
        });
    }
}
</script>

<style lang="scss">
    @import "assets/scss/style.scss";

    .footer-logo::after{
    text-align: center;
    content: "大自然能源電業股份有限公司";
    margin: 0 auto;
    display: block;
    color: #007fc4;
    }
</style>

