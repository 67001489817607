// import moment from 'moment'

export default {
   
    _isMobile: state => (code) => {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    
    /**
     * @param date 日期 
     * @param type 類型 0:完整日期、1:西元年月日、2:月日、3:時分秒
     * @returns 
     */
    // dateFormat: state => (date, type) => {

    //     var dateObj = (date == "") ? (new Date()) : (new Date(date))

    //     var formatStr = ""
    //     switch (type) {
    //         case 0:
    //             formatStr = "YYYYMMDD"
    //             break;
    //         case 1:
    //             formatStr = "YYYY-MM-DD"
    //             break;
    //         case 2:
    //             formatStr = "MM-DD"
    //             break;
    //         case 3:
    //             formatStr = "HH:mm:ss"
    //             break;
    //         case 4:
    //             formatStr = "MM-DD HH:mm:ss"
    //             break;
    //         case 5:
    //             formatStr = "MM/DD"
    //             break;
    //         default:
    //             formatStr = "YYYY-MM-DD HH:mm:ss"
    //             break;
    //     }

    //     var date_format = moment(dateObj).format(formatStr)
    //     if (date_format == "Invalid date") date_format = date
    //     return date_format
    // },
}