import request from '@/utils/request'

export function getArticle(paras) {
  let dataQuery = new URLSearchParams(paras).toString()
  
  return request({
    url: '/article?' + dataQuery,
    method: 'get'
  })
}
