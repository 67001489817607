<template>
    <main class="inside-wrap">
        <!-- banner -->
        <div class="banner-wrap page-client">
            <div class="banner-title">
                <figure>
                    <h2>客戶專區</h2>
                    <h3>MEMBER</h3>
                </figure>
            </div>
        </div>
        <!-- main -->
        <div class="inside-main no-padding">
            <div class="client-wrap">
                <div class="inside-center">
                    <div class="client-main">
                        <div class="client-group">
                            <h2 class="client-title">請選擇檢視年份</h2>
                            <div class="client-select">
                                <select name="" id="">
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                </select>
                            </div>
                        </div>
                        <div class="client-group">
                            <div class="client-report">
                                <h2 class="client-title"><b>2022</b> 年 整年轉供量及費用</h2>
                                <div class="report-overflow">
                                    <div class="report-flex">
                                        <div class="head">
                                            <div class="f-1">綠電類型</div>
                                            <div class="f-1">年累積綠電轉供電量<b>kWh(度)</b></div>
                                            <div class="f-1">年累計綠電轉供費用</div>
                                        </div>
                                        <div class="row">
                                            <div class="f-1 t-left">太陽光電</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                        </div>
                                        <div class="row">
                                            <div class="f-1 t-left">風力發電</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                        </div>
                                        <div class="row">
                                            <div class="f-1 t-left">水力發電</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                            <div class="f-1 t-right">1,000,000</div>
                                        </div>
                                        <div class="bottom">
                                            <div class="f-1 t-left">總計</div>
                                            <div class="f-1 t-right">3,000,000</div>
                                            <div class="f-1 t-right">3,000,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="client-group">
                            <div class="client-report">
                                <h2 class="client-title"><b>2022</b> 年 各月轉供電量及費用</h2>
                                <div class="client-accordin is-active">
                                    <div class="ca-title is-active">
                                        <span>10月份</span>
                                    </div>
                                    <div class="ca-content" style="display: block;">
                                        <div class="report-overflow">
                                            <div class="report-flex">
                                                <div class="head">
                                                    <div class="f-1">綠電類型</div>
                                                    <div class="f-1">年累積綠電轉供電量<b>kWh(度)</b></div>
                                                    <div class="f-1">年累計綠電轉供費用</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">太陽光電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">風力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">水力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="bottom">
                                                    <div class="f-1 t-left">總計</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-accordin">
                                    <div class="ca-title">
                                        <span>11月份</span>
                                    </div>
                                    <div class="ca-content">
                                        <div class="report-overflow">
                                            <div class="report-flex">
                                                <div class="head">
                                                    <div class="f-1">綠電類型</div>
                                                    <div class="f-1">年累積綠電轉供電量<b>kWh(度)</b></div>
                                                    <div class="f-1">年累計綠電轉供費用</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">太陽光電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">風力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">水力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="bottom">
                                                    <div class="f-1 t-left">總計</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-accordin">
                                    <div class="ca-title">
                                        <span>12月份</span>
                                    </div>
                                    <div class="ca-content">
                                        <div class="report-overflow">
                                            <div class="report-flex">
                                                <div class="head">
                                                    <div class="f-1">綠電類型</div>
                                                    <div class="f-1">年累積綠電轉供電量<b>kWh(度)</b></div>
                                                    <div class="f-1">年累計綠電轉供費用</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">太陽光電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">風力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="row">
                                                    <div class="f-1 t-left">水力發電</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                    <div class="f-1 t-right">1,000,000</div>
                                                </div>
                                                <div class="bottom">
                                                    <div class="f-1 t-left">總計</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                    <div class="f-1 t-right">3,000,000</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inside-bottom"></div>
        </div>
    </main>
</template>

<script>
export default {
    mounted() {
        // accordin
        $(".ca-title").on("click", function(e){
            if($(this).parent().has(".ca-content")) {
                e.preventDefault();
            }
            if(!$(this).hasClass("is-active")) {
                $(".ca-content").slideUp();
                $(".ca-title").removeClass("is-active");
                $(".ca-title").parent().removeClass("is-active");
                $(this).next(".ca-content").slideDown();
                $(this).addClass("is-active");
                $(this).parent().addClass("is-active");
            }
            else if($(this).hasClass("is-active")) {
                $(this).removeClass("is-active");
                $(this).parent().removeClass("is-active");
                $(this).next(".ca-content").slideUp();
            }
        });
    }
}
</script>
