<template>
  <main class="inside-wrap">
    <!-- banner -->
    <div class="banner-wrap page-report">
      <div class="banner-title">
        <figure>
          <h2>公開資訊</h2>
          <h3>REPORT</h3>
        </figure>
      </div>
    </div>
    <!-- main -->
    <div class="inside-main no-padding">
      <div class="report-wrap">
        <div class="inside-center">
          <div class="report-main">
            <div class="report-group">
              <h2 class="report-title">請選擇檢視年份</h2>
              <div class="report-select">
                <select v-model="year">
                  <template v-for="index in 15">
                    <option
                      v-if="(1901 + new Date().getYear() - index) > 2021"
                      :key="index"
                      :value="1901 + new Date().getYear() - index"
                    >
                      {{ 1901 + new Date().getYear() - index }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="report-group">
              <h2 class="report-title">年報</h2>
              <div class="report-list">
                <ul>
                  <template v-for="(item, key) in yearReport">
                    <li v-if="item.address != ''" :key="key">
                      <a href="javascript:void(0)" @click="downloadBlobByGet(item.address)">{{ item.name }}</a>
                    </li>
                  </template>
                  <li v-if="yearReport.length == 0">
                    無資料。
                  </li>
                </ul>
              </div>
            </div>
            <div class="report-group">
              <h2 class="report-title">月報</h2>
              <div class="report-list">
                <ul>
                  <template v-for="(item, key) in MonthReport">
                    <li v-if="item.address != ''" :key="key">
                      <a href="javascript:void(0)" @click="downloadBlobByGet(item.address)">{{ item.name }}</a>
                    </li>
                  </template>
                  <li v-if="MonthReport.length == 0">
                    無資料。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inside-bottom"></div>
    </div>
  </main>
</template>
<script>
import { getReport } from "@/api/report";

export default {
  name: "ReportView",
  watch: {
    year() {
      this.getReport();
    },
  },
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILES_URI,
      year: 1900 + new Date().getYear(),
      yearReport: [],
      MonthReport: [],
    };
  },
  computed: {},
  methods: {
    // 取得合作夥伴
    getReport() {
      var paras = {};
      paras.year = this.year;
      getReport(paras).then((response) => {
        if (response.code === 200) {
          this.MonthReport = response.data.filter((item) => item.kind == 1);
          this.yearReport = response.data.filter((item) => item.kind == 2);
        }else{
          this.MonthReport = [];
          this.yearReport = [];
        }
      });
    },
    downloadBlobByGet(fileName) {
      let urlGet = this.fileUrl + fileName;
      window.open(urlGet, "_blank")
    },
  },
  created() {
    // 取得資料
    this.getReport();
  },
  mounted() {},
};
</script>