

// import parseTime, formatTime and set to filter
// export { parseTime, formatTime } from '@/utils'
const filters = {
  /**
   * Show plural label if time is plural number
   * @param {number} time
   * @param {string} label
   * @return {string}
   */
  pluralize(time, label) {
    if (time === 1) {
      return time + label
    }
    return time + label + 's'
  },

  /**
 * @param {number} time
 */
  timeAgo(time) {
    const between = Date.now() / 1000 - Number(time)
    if (between < 3600) {
      return pluralize(~~(between / 60), ' minute')
    } else if (between < 86400) {
      return pluralize(~~(between / 3600), ' hour')
    } else {
      return pluralize(~~(between / 86400), ' day')
    }
  },
  /**
   * Number formatting
   * like 10000 => 10k
   * @param {number} num
   * @param {number} digits
   */
  numberFormatter(num, digits) {
    const si = [
      { value: 1E18, symbol: 'E' },
      { value: 1E15, symbol: 'P' },
      { value: 1E12, symbol: 'T' },
      { value: 1E9, symbol: 'G' },
      { value: 1E6, symbol: 'M' },
      { value: 1E3, symbol: 'k' }
    ]
    for (let i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      }
    }
    return num.toString()
  },

  /**
   * 10000 => "10,000"
   * @param {number} num
   */
  toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  },

  /**
   * Upper case first char
   * @param {String} string
   */
  uppercaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  /**
   * 2022-05-10T01:55:23.491Z => YYYY-mm-dd HH:ii:ss
   * @param {date} dt
   * @param {number} kind 
   * 1:YYYY-mm-dd
   */

  dateFormat(dt,kind = null) {
    if (!dt) return ''
    dt = new Date(dt)
    var f = function (d) { return d < 10 ? '0' + d : d }
    if(kind == 1){
      return dt.getFullYear() + '-' + f(dt.getMonth() + 1) + '-' + f(dt.getDate())  
    }
    return dt.getFullYear() + '-' + f(dt.getMonth() + 1) + '-' + f(dt.getDate()) + ' ' + f(dt.getHours()) + ':' + f(dt.getMinutes()) + ':' + f(dt.getSeconds())
  }
}

export default filters;