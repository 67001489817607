import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import filters from "./filters";


const app = createApp(App)
// 過濾器
app.config.globalProperties.$filters = filters
// 圖片路徑
app.config.globalProperties.imgUri = process.env.VUE_APP_IMAGE_URI

// Sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
app.use(VueSweetalert2);

app.use(store)

app.use(router).mount("#app");