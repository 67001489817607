<template>
    <main>
        <div class="index-bg-wrap">
            <!-- slider -->
            <div class="index-slider-wrap">
                <div class="index-slider-main">
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <div v-for="(item, key) in article" :key="key" class="swiper-slide">
                            <figure>
                                <h2 v-html="item.title"></h2>
                                <h3 v-html="item.content"></h3>
                                <h4>Your Renewable Partners</h4>
                                <a :href="item.link" target="_blank">繼續閱讀</a>
                                <!-- <router-link to="/buy">購買綠電</router-link> -->
                            </figure>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
            <!-- news -->
            <div class="index-news-wrap" data-aos="fade-up">
                <ul class="index-news-list">
                    <li v-for="(value, key) in news" :key="key" class="index-news-item">
                        <a :href="value.link" target="_blank">
                            <figure>
                                <figcaption>
                                    <h2 v-html="value.title"></h2>
                                    <time>{{$filters.dateFormat(value.add_date,1)}}</time>
                                    <p v-html="value.content"></p>
                                </figcaption>
                            </figure>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- step -->
        <div class="index-step-wrap">
            <div class="index-step-center">
                <h2 class="index-step-title">購電步驟</h2>
                <ul class="index-step-list">
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>設定綠電使用目標</span>
                        </h2>
                    </li>
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>了解再生能源需求量</span>
                        </h2>
                    </li>
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>向售電商洽談</span>
                            <span>聯絡<b>大自然</b></span>
                        </h2>
                    </li>
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>研擬購售電契約</span>
                            <span>PPA</span>
                        </h2>
                    </li>
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>台電協助轉供綠電</span>
                        </h2>
                    </li>
                    <li class="index-step-item" data-aos="fade-up">
                        <figure></figure>
                        <h2>
                            <span>取得憑證</span>
                        </h2>
                    </li>
                </ul>
            </div>
        </div>
        <!-- intro -->
        <div class="index-intro-wrap">
            <div class="index-intro-text">
                <div class="intro">
                    <div class="center">
                        <div class="right text-left" data-aos="fade-up">
                            <h2><b>大自然能源</b>為能源局核准許可之再生能源售電業</h2>
                            <h3>成立於2021年8月25日，公司使命為提供企業之100%綠電投資、開發、供應及顧問之專業服務。</h3>
                            <p>公司主要服務客戶象為企業用戶，以達成自主企業減碳目標為主要客群(如：RE100、供應鏈等)。營運至今已協助客戶提供超額超過5,000萬度之綠電來源規畫，對於現階段客戶急迫於降低排碳之情況下，大自然可協助企業找到合理、足夠數量並長期規劃之方案，配合客戶一同成長及能源轉型。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="index-intro-contact">
                <div class="content" data-aos="fade-up">
                    <figure>
                        <span>已協助綠電使用規劃</span>
                        <h2>
                            <b>5000</b>
                            <span>萬度+</span>
                        </h2>
                        <router-link to="/contact">前往諮詢</router-link>
                    </figure>
                </div>
            </div>
            <div class="index-intro-text">
                <div class="partner">
                    <div class="center">
                        <div class="right" data-aos="fade-up">
                            <h2>我們的合作夥伴</h2>
                            <ul>
                                <li v-for="(item,key) in partner" :key="key">
                                    <a :href="item.context" target="_blank">
                                        <figure>
                                            <img :src="`${imgUri}${item.url}`" :alt="item.context">
                                        </figure>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { getArticle } from '@/api/article'
import { getWebconfig } from '@/api/webconfig'

export default {
    name: "HomeView",
    data() {
        return {
            article: [],
            news: [],
            partner: []
        }
    },
    computed: {},
    methods: {
        // 取得新聞
        getNews() {
            var paras = {}
            paras.kind = 1
            paras.pageSize = 3
            paras.pageNum = 1
            getArticle(paras).then((response) => {
                if(response.code === 200){
                    this.news = response.data
                }                
            })
        },
        // 取得專題文章
        getArticleData() {
            var paras = {}
            paras.kind = 0
            paras.pageSize = 5
            paras.pageNum = 1
            getArticle(paras).then((response) => {
                if(response.code === 200){
                    this.article = response.data
                }                
            })
        },
        // 取得合作夥伴
        getPartner() {
            var paras = {}
            paras.kind = 5
            getWebconfig(paras).then((response) => {
                if(response.code === 200){
                    this.partner = response.data
                }                
            })
        }
    },
    created() {
        // 取得本月文章
        this.getArticleData()
        // 取得新聞
        this.getNews()
        // 取得合作夥伴
        this.getPartner()
    },
    mounted() {
        // slider
        const swiper = new Swiper('.index-slider-main', {
            direction: "horizontal",
            autoplay: {
                delay: 5000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
            },
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        // scroll animation
        AOS.init({
            once: true,
            easing: "ease-in-out-sine"
        });
    }
}
</script>

<style lang="scss">
.swiper-slide {
    h2, h3 {
        text-align: left;
    }
    h3{
        padding: 0 10px;
    }
}

.index-intro-text {
    .text-left{
        text-align: left;
    }
}
</style>