<template>
  <main class="inside-wrap">
    <!-- banner -->
    <div class="banner-wrap page-contact">
      <div class="banner-title">
        <figure>
          <h2>聯絡我們</h2>
          <h3>CONTACT US</h3>
        </figure>
      </div>
    </div>
    <!-- main -->
    <div class="inside-main no-padding">
      <div class="contact-wrap">
        <div class="inside-center">
          <div class="contact-main">
            <div class="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.124677355541!2d121.57919837584879!3d25.06376303709155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab80111ed4ed%3A0xc615870019397299!2zMTE05Y-w5YyX5biC5YWn5rmW5Y2A5paw5rmW5LqM6LevMjU36Jmf!5e0!3m2!1szh-TW!2stw!4v1670771590865!5m2!1szh-TW!2stw"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div class="contact-info">
              <div class="contact-content">
                <div class="contact-center">
                  <figure>
                    <p>
                      <span>如果對我們有任何問題，歡迎與我們聯繫</span>
                      <span>服務電話：{{ContactInfo.phone}}</span>
                      <span>服務時間：{{ContactInfo.serviceTime}}</span>
                    </p>
                  </figure>
                  <div class="contact-form">
                    <!-- <form action=""> -->
                    <div class="contact-row">
                      <div class="contact-input isneed">
                        <input
                          v-model="contactParas.name"
                          type="text"
                          id="iName"
                          autocomplete="off"
                        />
                        <label for="iName"
                          >姓名</label
                        >
                      </div>
                    </div>
                    <div class="contact-row">
                      <div class="contact-input">
                        <input
                          v-model="contactParas.unit"
                          type="text"
                          id="iUnit"
                          autocomplete="off"
                        />
                        <label for="iUnit">單位</label>
                      </div>
                      <div class="contact-input">
                        <input
                          v-model="contactParas.jobTitle"
                          type="text"
                          id="iJob"
                          autocomplete="off"
                        />
                        <label for="iJob">職稱</label>
                      </div>
                    </div>
                    <div class="contact-row">
                      <div class="contact-input isneed">
                        <input
                          v-model="contactParas.phone"
                          type="text"
                          id="iTel"
                          autocomplete="off"
                        />
                        <label for="iTel">電話</label>
                      </div>
                      <div class="contact-input isneed">
                        <input
                          v-model="contactParas.email"
                          type="text"
                          id="iMail"
                          autocomplete="off"
                        />
                        <label for="iMail"
                          >信箱</label
                        >
                      </div>
                    </div>
                    <div class="contact-row">
                      <div class="contact-input isneed select-item">
                        <select v-model="contactParas.type" id="iItem">
                          <option
                            v-for="(item, index) in contactType"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <label for="iItem"
                          >諮詢項目</label
                        >
                      </div>
                    </div>
                    <div class="contact-row">
                      <div class="contact-input">
                        <textarea
                          v-model="contactParas.content"
                          name=""
                          id=""
                          placeholder="*請輸入您想諮詢的內容"
                          autocomplete="off"
                        ></textarea>
                      </div>
                    </div>
                    <div class="contact-btn">
                      <button @click="submitContact">送出</button>
                    </div>
                    <!-- </form> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inside-bottom"></div>
    </div>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      color="#2996cc"
      background-color="#000"
      opacity="0.3"
      height="100"
    />
  </main>
</template>

<script>
import { getContactType, postContact } from "@/api/contact";
import { getWebconfig } from '@/api/webconfig'
// loading bar
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  data() {
    return {
      contactType: [],
      contactParas: {
        name: "",
        unit: "",
        jobTitle: "",
        phone: "",
        email: "",
        type: 1,
        content: "",
      },      
      isLoading: false,
      fullPage: true,
      ContactInfo: {
        address:"臺北市內湖區新湖二路257號3樓",
        phone:"02-87918368",
        email:"service@naturalpower.com"
      }
    };
  },
  components: {
    Loading,
  },
  created() {
    // 取得聯絡我們類型
    this.initContactType();
    // 取得關於我們
    this.getContactInfo()
  },
  methods: {
    // 取得聯絡我們類型
    initContactType() {
      var paras = {};
      paras.kind = 1;
      paras.pageSize = 10;
      paras.pageNum = 1;
      getContactType(paras).then((response) => {
        if (response.code === 200) {
          this.contactType = response.data;
        }
      });
    },

    // 取得聯繫資訊
    getContactInfo() {
        var paras = {}
        paras.kind = 1
        getWebconfig(paras).then((response) => {
            if(response.code === 200){
                const info = JSON.parse(response.data[0].context)
                if(info.address != null && info.phone != null && info.email != null)
                    this.ContactInfo = info
            }                
        })
    },

    // 送出聯絡我們
    submitContact() {
      if (this.isLoading) return false;

			// email 驗證
			//eslint-disable-next-line
			const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
			if(this.contactParas.email.search(emailRule) == -1){
					this.$swal({
            icon: "error",
            title: "Email錯誤",
            text: "請確認資訊是否填寫正確!",
            confirmButtonText: "確定",
          });
					return false
			}

      this.isLoading = true;
      postContact(this.contactParas).then((response) => {
        if (response.code === 201) {
          this.contactParas = {
            name: "",
            unit: "",
            jobTitle: "",
            phone: "",
            email: "",
            type: 1,
            content: "",
          };
          this.$swal({
            icon: "success",
            title: "成功",
            text: "我們將盡快與您聯繫。",
            confirmButtonText: "確定",
          });
        } else {
          this.$swal({
            icon: "error",
            title: "送出失敗",
            text: "請確認資訊是否填寫完畢!",
            confirmButtonText: "確定",
          });
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-input.isneed::before {
    content: "*";
    position: absolute;
    top: calc(50% - 9px);
    left: 13px;
    font-size: 25px;
    color: #ff0000;
    z-index: 1;
}
</style>