import { login } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  user_info: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, user_data) => {
    state.user_info = user_data
  }
}

const actions = {
  // user login
  login({ commit }, paras) {
    return new Promise((resolve, reject) => {
      login(paras).then(response => {
        const token = response.data.token
        commit('SET_USER_INFO', response.data)
        commit('SET_TOKEN', token)
        setToken(token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    commit('SET_TOKEN', null)
    removeToken()
  },

  // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     commit('SET_TOKEN', '')
  //     commit('SET_ROLES', [])
  //     removeToken()
  //     resolve()
  //   })
  // },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
